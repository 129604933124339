export enum MetriportMsg {
	PT_NOT_FOUND = "Participant not found",
	NO_EXISTING_REPORT = "No job found",
	PROCESSING = "Job has started processing in the background",
}

export enum MetriportQueryStatus {
	processing = "processing",
	failed = "failed",
	completed = "completed",
}

export type MetriportReportResponse = MetriportReportErrorResponse | MetriportReportDataResponse;

export type MetriportReportErrorResponse = {
	message: MetriportMsg;
};

export type MetriportReportDataResponse = {
	created_on: string; // Date
	external_id: string; // Gladstone ID
	id: string;
	message_type: "medical.consolidated-data";
	status: MetriportQueryStatus;
	metadata: string; // Stringified JSON: MetriportConsolidatedData
};
