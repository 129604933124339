import { useTranslation } from "react-i18next";
import { ParticipantContextProps } from "../../../models/contexts/participant-context.model";
import { useRef, useState } from "react";
import { OverlayPanel } from "primereact/overlaypanel";
import ReactMarkdown from "react-markdown";
import { useInstitutionDetails } from "./useInstitutionDetails";
import remarkGfm from "remark-gfm"; // Optional for GitHub-flavored markdown

const ParticipantInstitutionDetails = ({
	participantContext,
}: {
	participantContext: ParticipantContextProps;
}) => {
	const { t } = useTranslation("common");

	const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
	const op = useRef<OverlayPanel>(null);

	const details = useInstitutionDetails({
		institutionId: participantContext.participant!.institutionId,
	});

	if (participantContext.participant?.institution === undefined) {
		return <i>{t("PARTICIPANT.PROFILE.FIELDS.NO_EMPLOYER")}</i>;
	}
	return (
		<div className="d-flex">
			<span className="institution-details-title">
				{participantContext.participant?.institution}
			</span>
			<button
				className="btn bg-transparent p-0 institution-details_button-no-focus"
				onClick={(e) => {
					op.current?.toggle(e);
				}}
			>
				<span className={isCollapsed ? "pi pi-chevron-down" : "pi pi-chevron-up"}></span>
			</button>

			<OverlayPanel
				ref={op}
				appendTo={this}
				onShow={() => setIsCollapsed(false)}
				onHide={() => setIsCollapsed(true)}
				className="institution-details_overlaypanel"
			>
				{details.data?.details ? (
					<ReactMarkdown
						className={"institution-details_overlaypanel__markdown"}
						children={details.data.details}
						remarkPlugins={[remarkGfm]}
					/>
				) : (
					<span>{t("PARTICIPANT.PROFILE.INSTITUTION_DETAILS.EMPTY")}</span>
				)}
			</OverlayPanel>
		</div>
	);
};

export default ParticipantInstitutionDetails;
