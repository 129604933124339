import { matchRoutes, Navigate, RouteObject } from "react-router-dom";
import { BehaviorSubject } from "rxjs";
// Models
import { PageProps } from "../../models/routing.model";
// Services
import { getRoute, ProtectedRoute } from "./protected-routes.service";
// Icons
import {
	BsFillAlarmFill,
	BsFillTelephoneFill,
	BsFillHouseFill,
	BsTable,
	BsTextareaResize,
} from "react-icons/bs";
import { FaCalendarAlt, FaVoicemail } from "react-icons/fa";
import { VscSymbolString } from "react-icons/vsc";
import { CgPassword, CgSelectR } from "react-icons/cg";
import { TiSortNumerically } from "react-icons/ti";
import { TbCheckbox, TbActivityHeartbeat } from "react-icons/tb";
import { RiUserSettingsFill, RiSurveyFill, RiUserHeartFill } from "react-icons/ri";
import { MdRadioButtonChecked } from "react-icons/md";
import { IoAlertCircle } from "react-icons/io5";
import { ReactComponent as IconSUG } from "../../assets/img/menu_icon_participant_sug.svg";
// Pages
import Login from "../../pages/login/Login";
import Main from "../../pages/main/Main";
import CallCenter from "../../pages/call-center/CallCenter";
import CallCenterDashboard from "../../pages/call-center/dashboard/Dashboard";
import CallCenterHistory from "../../pages/call-center/history/History";
import RiskUsers from "../../pages/risk-users/RiskUsers";
import CallCenterVoicemail from "../../pages/call-center/voicemail/Voicemail";
import ParticipantsDashboard from "../../pages/participants/dashboard/ParticipantsDashboard";
import Participant from "../../pages/participant/Participant";
import ParticipantDashboard from "../../pages/participant/dashboard/ParticipantDashboard";
import Showcases from "../../pages/showcases/Showcases";
import InputTextShowcase from "../../pages/showcases/input-text/InputTextShowcase";
import InputNumberShowcase from "../../pages/showcases/input-number/InputNumberShowcase";
import SelectShowcase from "../../pages/showcases/select/SelectShowcase";
import InputPasswordShowcase from "../../pages/showcases/input-password/InputPasswordShowcase";
import InputDateShowcase from "../../pages/showcases/input-date/InputDateShowcase";
import TableShowcase from "../../pages/showcases/table/TableShowcase";
import SimpleTableShowcase from "../../pages/showcases/table/showcases/SimpleTableShowcase";
import TemplatesTableShowcase from "../../pages/showcases/table/showcases/TemplatesTableShowcase";
import Participants from "../../pages/participants/Participants";
import ParticipantsReminders from "../../pages/participants/reminders/ParticipantsReminders";
import ParticipantReminders from "../../pages/participant/reminders/ParticipantReminders";
import InputTextareaShowcase from "../../pages/showcases/input-textarea/InputTextareaShowcase";
import FullTableShowcase from "../../pages/showcases/table/showcases/FullTableShowcas";
import SelectMultipleShowcase from "../../pages/showcases/select-multiple/SelectMultipleShowcase";
import ParticipantServiceUtilizationGoals from "../../pages/participant/service-utilization-goals/ParticipantServiceUtilizationGoals";
import InputCheckboxShowcase from "../../pages/showcases/input-checkbox/InputCheckboxShowcase";
import ParticipantMessages from "../../pages/participant/messages/Messages";
import ConversationMenu from "../../pages/participant/messages/care-messages/ConversationMenu";
import ParticipantDetails from "../../pages/participant/details/ParticipantDetails";
import Callback from "../../pages/callback/Callback";
import ParticipantsMessages from "../../pages/participants/messages/ParticipantsMessages";
import ParticipantsMessagesIndicator from "../../pages/participants/messages/ParticipantsMessagesIndicator";
import InputRadioShowcase from "../../pages/showcases/input-radio/InputRadioShowcase";
import ParticipantsAppointments from "../../pages/participants/appointments/ParticipantsAppointments";
import ParticipantAppointments from "../../pages/participant/appointments/ParticipantAppointments";
import ParticipantSurveys from "../../pages/participant/surveys/ParticipantSurveys";
import Settings from "../../pages/settings/Settings";
import SettingsUsers from "../../pages/settings/users/SettingsUsers";
import { AuthRoles } from "../../models/roles.model";
import ButtonShowcase from "../../pages/showcases/button/ButtonShowcase";
import ParticipantBiometrics from "../../pages/participant/biometrics/ParticipantBiometrics";
import BiometricsReport from "../../pages/participant/biometrics/BiometricsReport";
import BiometricsCharts from "../../pages/participant/biometrics/charts/BiometricsCharts";
import BiometricsList from "../../pages/participant/biometrics/list/BiometricsList";
import ParticipantsAlerts from "../../pages/participants/alerts/ParticipantsAlerts";
import { ProtectedComponent } from "./protected-component.service";
import DebugUnauthorizedErrors from "../../pages/debug-unauthorized-errors/DebugUnauthorizedErrors";

// Application routes
const routes: RouteObject[] = [
	{
		path: "login",
		element: <Login id="login" />,
	},
	{
		path: "callback",
		element: <Callback />,
	},
	{
		path: "/",
		element: (
			<ProtectedRoute>
				<Main />
			</ProtectedRoute>
		),
		children: [
			{
				index: true,
				element: (
					<>
						<ProtectedComponent deniedRoles={[AuthRoles.PROVIDER]}>
							<Navigate to="call-center" />
						</ProtectedComponent>
						<ProtectedComponent allowedRoles={[AuthRoles.PROVIDER]}>
							<Navigate to="participants" />
						</ProtectedComponent>
					</>
				),
			},
			// { index: true, element: <Navigate to="home" /> },
			// {
			//     path: 'home',
			//     element: <Home id='home' title='HOME.TITLE' isModule={true} />
			// },
			{
				path: "call-center",
				element: (
					<ProtectedComponent deniedRoles={[AuthRoles.PROVIDER]}>
						<CallCenter
							id="call-center"
							icon={<BsFillTelephoneFill />}
							title="CALL_CENTER.TITLE"
							isModule={true}
						/>
					</ProtectedComponent>
				),
				children: [
					{ index: true, element: <Navigate to="dashboard" /> },
					{
						path: "dashboard",
						element: (
							<CallCenterDashboard
								id="call-center/dashboard"
								icon={<BsFillHouseFill />}
								title="CALL_CENTER.DASHBOARD.TITLE"
								isSubModule={true}
							/>
						),
					},
					{
						path: "history",
						element: (
							<CallCenterHistory
								id="call-center/history"
								icon={<BsFillTelephoneFill />}
								title="CALL_CENTER.HISTORY.TITLE"
								isSubModule={true}
							/>
						),
					},
					{
						path: "voicemail",
						element: (
							<CallCenterVoicemail
								id="call-center/voicemail"
								icon={<FaVoicemail />}
								title="CALL_CENTER.VOICEMAIL.TITLE"
								isSubModule={true}
							/>
						),
					},
				],
			},
			{
				path: "participants",
				element: (
					<Participants
						id="participants"
						title="PARTICIPANTS.TITLE"
						isModule={true}
					/>
				),
				children: [
					{ index: true, element: <Navigate to="dashboard" /> },
					{
						path: "dashboard",
						element: (
							<ParticipantsDashboard
								id="participants/dashboard"
								icon={<BsFillHouseFill />}
								title="PARTICIPANTS.SIDEBAR_MENU.0"
								isSubModule={true}
							/>
						),
					},
					{
						path: "appointments",
						element: (
							<ProtectedComponent deniedRoles={[AuthRoles.PROVIDER]}>
								<ParticipantsAppointments
									id="participants/appointments"
									icon={<FaCalendarAlt />}
									title="PARTICIPANTS.SIDEBAR_MENU.1"
									isSubModule={true}
								/>
							</ProtectedComponent>
						),
					},
					{
						path: "reminders",
						element: (
							<ProtectedComponent deniedRoles={[AuthRoles.PROVIDER]}>
								<ParticipantsReminders
									id="participants/reminders"
									icon={<BsFillAlarmFill />}
									title="PARTICIPANTS.SIDEBAR_MENU.2"
									isSubModule={true}
								/>
							</ProtectedComponent>
						),
					},
					{
						path: "messages",
						element: (
							<ProtectedComponent deniedRoles={[AuthRoles.PROVIDER]}>
								<ParticipantsMessages
									id="participants/messages"
									icon={<ParticipantsMessagesIndicator />}
									title="PARTICIPANTS.SIDEBAR_MENU.3"
									isSubModule={true}
								/>
							</ProtectedComponent>
						),
					},
					{
						path: "alerts",
						element: (
							<ParticipantsAlerts
								id="participants/alerts"
								icon={<IoAlertCircle fontSize="1.5rem" />}
								title="PARTICIPANTS.SIDEBAR_MENU.4"
								isSubModule={true}
							/>
						),
					},
				],
			},
			{
				path: "participant/:participantId",
				element: (
					<Participant
						id="participant"
						title="PARTICIPANT.TITLE"
						isVisible={false}
						isModule={true}
					/>
				),
				children: [
					{ index: true, element: <Navigate to="dashboard" /> },
					{
						path: "dashboard",
						element: (
							<ParticipantDashboard
								id="participant/dashboard"
								icon={<BsFillHouseFill />}
								title="PARTICIPANT.SIDEBAR_MENU.0"
								isSubModule={true}
							/>
						),
					},
					{
						path: "biometrics",
						element: (
							<ParticipantBiometrics
								id="participant/biometrics"
								icon={<TbActivityHeartbeat fontSize="1.75rem" />}
								title="PARTICIPANT.SIDEBAR_MENU.7"
								isSubModule={true}
							/>
						),
						children: [
							{
								index: true,
								element: (
									<Navigate
										to="list"
										replace={true}
									/>
								),
							},
							{
								path: "list",
								element: <BiometricsList />,
							},
							{
								path: "charts",
								element: <BiometricsCharts />,
							},
							{
								path: "report",
								element: <BiometricsReport />,
							},
						],
					},
					{
						path: "appointments",
						element: (
							<ParticipantAppointments
								id="participant/appointments"
								icon={<FaCalendarAlt />}
								title="PARTICIPANT.SIDEBAR_MENU.1"
								isSubModule={true}
							/>
						),
						children: [
							{ index: true, element: <Navigate to="upcoming" /> },
							{
								path: "upcoming",
								element: (
									<ParticipantAppointments
										id="participant/appointments"
										icon={<FaCalendarAlt />}
										title="SHOWCASES.SIDEBAR_MENU.1"
									/>
								),
							},
							{
								path: ":type",
								element: (
									<ParticipantAppointments
										id="participant/appointments"
										icon={<FaCalendarAlt />}
										title="PARTICIPANT.SIDEBAR_MENU.1"
									/>
								),
							},
						],
					},
					{
						path: "reminders",
						element: (
							<ParticipantReminders
								id="participant/reminders"
								icon={<BsFillAlarmFill />}
								title="PARTICIPANT.SIDEBAR_MENU.2"
								isSubModule={true}
							/>
						),
					},
					{
						path: "service-utilization-goals",
						element: (
							<ParticipantServiceUtilizationGoals
								id="participant/service-utilization-goals"
								icon={
									<IconSUG
										height="1.25em"
										width="1.25em"
										stroke="currentColor"
										fill="currentColor"
									/>
								}
								title="PARTICIPANT.SIDEBAR_MENU.3"
								isSubModule={true}
							/>
						),
					},
					{
						path: "messages",
						element: (
							<ProtectedComponent deniedRoles={[AuthRoles.PROVIDER]}>
								<ParticipantMessages
									id="participant/messages"
									icon={<ConversationMenu />}
									title="PARTICIPANT.SIDEBAR_MENU.4"
									isSubModule={true}
								/>
							</ProtectedComponent>
						),
					},
					{
						path: "details",
						element: (
							<ParticipantDetails
								id="participant/details"
								icon={<RiUserSettingsFill size="1.25em" />}
								title="PARTICIPANT.SIDEBAR_MENU.5"
								isSubModule={true}
							/>
						),
					},
					{
						path: "surveys",
						element: (
							<ParticipantSurveys
								id="participant/surveys"
								icon={<RiSurveyFill size="1.25em" />}
								title="PARTICIPANT.SIDEBAR_MENU.6"
								isSubModule={true}
							/>
						),
					},
				],
			},
			{
				path: "risk-users",
				element: (
					<ProtectedComponent deniedRoles={[AuthRoles.PROVIDER]}>
						<RiskUsers
							id="risk-users"
							title="RISK_USERS.TITLE"
							isModule={true}
						/>
					</ProtectedComponent>
				),
			},
			{
				path: "settings",
				element: (
					<ProtectedRoute allowedRoles={[AuthRoles.ADMIN]}>
						<Settings
							id="settings"
							title="SETTINGS.TITLE"
							isModule={true}
						/>
					</ProtectedRoute>
				),
				children: [
					{ index: true, element: <Navigate to="users" /> },
					{
						path: "users",
						element: (
							<SettingsUsers
								id="settings/users"
								icon={<RiUserHeartFill />}
								title="SETTINGS.SIDEBAR_MENU.0"
								isSubModule={true}
							/>
						),
					},
				],
			},
			{
				path: "showcases",
				element: (
					<Showcases
						id="showcases"
						title="SHOWCASES.TITLE"
						isVisible={false}
						isModule={true}
					/>
				),
				children: [
					{ index: true, element: <Navigate to="input-text" /> },
					{
						path: "input-text",
						element: (
							<InputTextShowcase
								id="showcases/input-text"
								icon={<VscSymbolString />}
								title="SHOWCASES.SIDEBAR_MENU.0"
								isSubModule={true}
							/>
						),
					},
					{
						path: "input-number",
						element: (
							<InputNumberShowcase
								id="showcases/input-number"
								icon={<TiSortNumerically />}
								title="SHOWCASES.SIDEBAR_MENU.1"
								isSubModule={true}
							/>
						),
					},
					{
						path: "input-password",
						element: (
							<InputPasswordShowcase
								id="showcases/input-password"
								icon={<CgPassword />}
								title="SHOWCASES.SIDEBAR_MENU.2"
								isSubModule={true}
							/>
						),
					},
					{
						path: "input-date",
						element: (
							<InputDateShowcase
								id="showcases/input-date"
								icon={<FaCalendarAlt />}
								title="SHOWCASES.SIDEBAR_MENU.3"
								isSubModule={true}
							/>
						),
					},
					{
						path: "input-textarea",
						element: (
							<InputTextareaShowcase
								id="showcases/input-textarea"
								icon={<BsTextareaResize />}
								title="SHOWCASES.SIDEBAR_MENU.6"
								isSubModule={true}
							/>
						),
					},
					{
						path: "select",
						element: (
							<SelectShowcase
								id="showcases/select"
								icon={<CgSelectR />}
								title="SHOWCASES.SIDEBAR_MENU.4"
								isSubModule={true}
							/>
						),
					},
					{
						path: "select-multiple",
						element: (
							<SelectMultipleShowcase
								id="showcases/select-multiple"
								icon={<CgSelectR />}
								title="SHOWCASES.SIDEBAR_MENU.7"
								isSubModule={true}
							/>
						),
					},
					{
						path: "table",
						element: (
							<TableShowcase
								id="showcases/table"
								icon={<BsTable />}
								title="SHOWCASES.SIDEBAR_MENU.6"
								isSubModule={true}
							/>
						),
						children: [
							{ index: true, element: <Navigate to="simple" /> },
							{
								path: "simple",
								element: (
									<SimpleTableShowcase
										id="showcases/table/simple"
										title="SHOWCASES.SIDEBAR_MENU.5"
									/>
								),
							},
							{
								path: "templates",
								element: (
									<TemplatesTableShowcase
										id="showcases/table/templates"
										title="SHOWCASES.SIDEBAR_MENU.5"
									/>
								),
							},
							{
								path: "full",
								element: (
									<FullTableShowcase
										id="showcases/table/full"
										title="SHOWCASES.SIDEBAR_MENU.5"
									/>
								),
							},
						],
					},
					{
						path: "input-checkbox",
						element: (
							<InputCheckboxShowcase
								id="showcases/input-checkbox"
								icon={<TbCheckbox />}
								title="SHOWCASES.SIDEBAR_MENU.8"
								isSubModule={true}
							/>
						),
					},
					{
						path: "input-radio",
						element: (
							<InputRadioShowcase
								id="showcases/input-radio"
								icon={<MdRadioButtonChecked />}
								title="SHOWCASES.SIDEBAR_MENU.9"
								isSubModule={true}
							/>
						),
					},
					{
						path: "button",
						element: (
							<ButtonShowcase
								id="showcases/button"
								icon={<VscSymbolString />}
								title="SHOWCASES.SIDEBAR_MENU.10"
								isSubModule={true}
							/>
						),
					},
				],
			},
		],
	},
	{
		path: "debug-unauthorized-errors",
		element: <DebugUnauthorizedErrors />,
	},
];

export const getRoutes = (): RouteObject[] => routes;

/**
 * Routes: route Element props
 * @returns Returns the props of the current route Element
 */
export const getCurrentRouteProps = (pathname: string): PageProps | undefined => {
	const matchedRoutes = matchRoutes(getRoutes(), pathname);

	let routeProps: PageProps | undefined;
	if (matchedRoutes) {
		matchedRoutes.forEach((mRoute) => {
			if (mRoute.pathname === pathname) {
				const route = getRoute(mRoute.route);
				routeProps = route?.routeProps;
			}
		});
	}

	return routeProps;
};

/**
 *
 */
export const roleSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
export const roleChnaged = (): void => roleSubject.next(true);
