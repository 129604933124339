const DebugUnauthorizedErrors = () => {
	const rawUnauthorizedErrors = window.localStorage.getItem("unauthorized-errors");

	if (!rawUnauthorizedErrors) {
		return null;
	}

	return (
		<>
			<a
				href={URL.createObjectURL(
					new Blob([rawUnauthorizedErrors], { type: "application/json" })
				)}
				download="unauthorized-errors.json"
			>
				Download unauthorized errors
			</a>
			<br />
			<br />
			<code>{rawUnauthorizedErrors}</code>;
		</>
	);
};

export default DebugUnauthorizedErrors;
