import { useQuery } from "@tanstack/react-query";
import HttpService from "../services/http/http.service";
import {
	MetriportMsg,
	MetriportQueryStatus,
	MetriportReportDataResponse,
	MetriportReportErrorResponse,
	MetriportReportResponse,
} from "../models/endpoints/metriport-endpoint.model";
import { AxiosError } from "axios";

const httpClient = new HttpService(process.env.REACT_APP_METRIPORT_API_URL!);

const waitQuery = async (patientId: number): Promise<MetriportReportDataResponse> => {
	return new Promise((resolve, reject) => {
		const interval = setInterval(async () => {
			// Get status
			const response = await getReport(patientId);
			// Verify if we need to refetch
			if ("status" in response && response["status"] === MetriportQueryStatus.completed) {
				clearInterval(interval);
				resolve(response);
			} else if ("status" in response && response["status"] === MetriportQueryStatus.failed) {
				clearInterval(interval);
				reject(response);
			}
		}, 5000); // Every 5 seconds
	});
};

const getReport = async (patientId: number): Promise<MetriportReportResponse> => {
	try {
		const response = await httpClient.get(`/report/${patientId}`);
		return response;
	} catch (err) {
		const error = ((err as AxiosError).response?.data as MetriportReportErrorResponse) ?? null;
		if (error?.message === MetriportMsg.PT_NOT_FOUND) {
			// Participant not found in Metriport
			throw new Error("PARTICIPANT.DASHBOARD.OVERVIEW.METRIPORT.STATE_ERROR_PATIENT");
		}
		throw new Error("UI_COMPONENTS.FIELD_MESSAGE.HTTP.ERROR2");
	}
};

export const useMetriport = ({ participantId }: { participantId: number }) => {
	const queryKey = ["metriport-medical-report", { participantId }];
	return useQuery(
		queryKey,
		async () => {
			/**
			 * /report/${participantId}
			 * This endpoint can return:
			 * 1) If the participant doesn't exist --> Error 404: { message: "Patient not found" }
			 * 2) If the participant exists and has a report already generated --> Success 200: { ..., metadata: string }
			 * 2) If the participant exists but has no generated report --> Success 202: { message: "Job has started processing in the background" }
			 * 	  2.1) In this case we have to poll the same endpoint until ir returns the generated report (waitquery())
			 */

			const response = await getReport(participantId);

			if (
				"message" in response &&
				(response["message"] === MetriportMsg.PROCESSING ||
					response["message"] === MetriportMsg.NO_EXISTING_REPORT)
			) {
				const response = await waitQuery(participantId);
				return response;
			} else {
				return response as MetriportReportDataResponse;
			}
		},
		{ retry: 0 }
	);
};
