export interface Physician {
	id: number;
	participantId: number;
	name: string;
	email: string;
	phone: string;
	fax: string;
	address: string;
	specialty: PhysicianSpecialty;
	deleted: boolean;
	sendHealthSummaryReport: boolean;
	verified: boolean;
	createdOn: string; // Date
	updatedOn: string; // Date
	deletedOn: string; // Date
}

export enum PhysicianSpecialty {
	Allergy = "Allergy",
	Cardiology = "Cardiology",
	Dermatology = "Dermatology",
	Endocrinology = "Endocrinology",
	Family_medicine = "Family_medicine",
	Gastroenterology = "Gastroenterology",
	General_practice = "General_practice",
	General_surgery = "General_surgery",
	Hematology = "Hematology",
	Infectious_diseases = "Infectious_diseases",
	Internal_medicine = "Internal_medicine",
	Nephrology = "Nephrology",
	Neurology = "Neurology",
	Oncology = "Oncology",
	Pulmonary_diseases = "Pulmonary_diseases",
	Psychiatry = "Psychiatry",
	Radiology = "Radiology",
	Rheumatology = "Rheumatology",
	Sports_medicine = "Sports_medicine",
	Urology = "Urology",
}
