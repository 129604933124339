import { useContext } from "react";
import { useTranslation } from "react-i18next";
import UiTag from "../../../components/tag/Tag.component";
import { Formatter } from "../../../services/formatter/formatter.service";
import UiPhoneCaller from "../../../components/phone-caller/PhoneCaller.component";
import { ParticipantContextProps } from "../../../models/contexts/participant-context.model";
import { ParticipantContext } from "../../../contexts/Participant.context";
import { HelperService } from "../../../services/helpers/helper.service";
import ParticipantHedisGoals from "./ParticipantHedisGoals";
import { BsFillStarFill } from "react-icons/bs";
import { TbStarOff } from "react-icons/tb";
import { useProvidersInfo } from "../../../hooks/useProvidersInfo";
import { ProviderMinimalInfo } from "../../../models/entities/provider.model";
import ParticipantProfileAppointment from "./ParticipantProfileAppointment";
import { PreferredPronouns, Timezone } from "../../../models/entities/participant.model";
import ParticipantInstitutionDetails from "./ParticipantInstitutionDetails";

const ParticipantProfileInfo = () => {
	const { t } = useTranslation("common");
	const participantContext = useContext<ParticipantContextProps>(ParticipantContext);
	const participantProfile = participantContext.participant!;

	const fullName = Formatter.fullName(
		participantProfile.firstName,
		participantProfile.lastName,
		participantProfile.preferredName
	);
	const status = Formatter.statusTag(participantProfile.status);
	const contactPurpose = HelperService.formatLastContactPurpose(
		participantProfile.contactPurpose
	);
	const email = participantProfile.emails?.find((p) => p.isPrimary)?.emailAddress ?? null;
	const phoneNumber = participantProfile.phones?.length
		? participantProfile.phones.find((p) => p.isPrimary)
		: null;
	const coaches = useProvidersInfo();
	const coachFullName = (coach?: ProviderMinimalInfo) => {
		let result = "";
		if (coach) {
			result = Formatter.fullName(coach.firstName, coach.lastName);
		} else {
			result = Formatter.NO_DATA;
		}
		return result;
	};

	return (
		<div className="participant-profile">
			{/* Primary info */}
			<div className="primary-info">
				{/* Primary info: first line */}
				<div className="primary-info__first">
					{/* Full name */}
					{fullName && <div className="name">{fullName}</div>}

					{/* Pronouns */}
					{participantProfile.pronouns &&
						participantProfile.pronouns !== PreferredPronouns.PREFER_NOT && (
							<div className="pronouns">{`(${t(`ENUMS.PRONOUNS.${participantProfile.pronouns}`)})`}</div>
						)}

					{/* Brand */}
					<img
						alt="brand logo"
						className="logo"
						src={participantProfile.brandLogo}
					/>
				</div>

				{/* Primary info: second line */}
				<div className="primary-info__second">
					{/* Status (user_state) */}
					{status && <UiTag {...status} />}

					{/* Not covered */}
					{!participantProfile.isCovered && (
						<span className="text-bold">
							{t("PARTICIPANT.PROFILE.FIELDS.NOT_COVERED")}
						</span>
					)}

					{/* Appointments */}
					<ParticipantProfileAppointment participantContext={participantContext} />

					{/* Phone number */}
					{phoneNumber?.countryCode && phoneNumber.phoneNumber ? (
						<UiPhoneCaller
							phoneNumber={`${phoneNumber?.countryCode}${phoneNumber.phoneNumber}`}
						/>
					) : (
						<i>{t("PARTICIPANT.PROFILE.FIELDS.NO_PHONE_NUMBER")}</i>
					)}
				</div>
			</div>

			{/* Secondary info */}
			<div className="secondary-info row">
				<div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
					<div className="row">
						{/* Kannact ID */}
						<div className="col-6 col-sm-12">{participantProfile.kannactId}</div>

						{/* DOB: Date Of Birth */}
						<div className="col-6 col-sm-12">
							{participantProfile?.dob ? (
								t("PARTICIPANT.PROFILE.FIELDS.DOB", {
									value: Formatter.dateISOToString(participantProfile.dob, {
										day: "numeric",
										month: "short",
										year: "numeric",
										timeZone: "UTC",
									}),
									age: Formatter.calculateAge(participantProfile.dob),
								})
							) : (
								<i>{t("PARTICIPANT.PROFILE.FIELDS.NO_DOB")}</i>
							)}
						</div>

						{/* Institution details */}
						<div className="col-6 col-sm-12">
							<ParticipantInstitutionDetails
								participantContext={participantContext}
							/>
						</div>

						{/* Assigned coach */}
						{coaches.data && (
							<div className="col-6 col-sm-12">
								{participantProfile?.coachId ? (
									coachFullName(
										coaches.data.find(
											(x) => x.coachId === participantProfile.coachId
										)
									)
								) : (
									<i>{t("PARTICIPANT.PROFILE.FIELDS.NO_ASSIGNED_COACH")}</i>
								)}
							</div>
						)}
					</div>
				</div>

				<div className="col-12 col-sm-4 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
					<div className="row">
						{/* Time zone */}
						<div className="col-6 col-sm-12">
							{!participantProfile.timezone ||
							participantProfile.timezone === Timezone.NA ? (
								<i>{t("PARTICIPANT.PROFILE.FIELDS.NO_TIMEZONE")}</i>
							) : (
								participantProfile.timezone
							)}
						</div>

						{/* Language */}
						<div className="col-6 col-sm-12">
							{participantProfile?.language ? (
								t(`ENUMS.LANGUAGE.${participantProfile.language}`)
							) : (
								<i>{t("PARTICIPANT.PROFILE.FIELDS.NO_LANGUAGE")}</i>
							)}
						</div>

						{/* Gender */}
						<div className="col-6 col-sm-12">
							{participantProfile?.gender === "M" ||
							participantProfile?.gender === "F" ? (
								t(Formatter.gender(participantProfile.gender))
							) : (
								<i>{t("PARTICIPANT.PROFILE.FIELDS.NO_GENDER")}</i>
							)}
						</div>

						{/* Contact purpose */}
						<div className="col-6 col-sm-12">
							{!participantProfile.contactPurpose ||
							!contactPurpose ||
							participantProfile.contactPurpose === "NONE" ? (
								<i>{t("PARTICIPANT.PROFILE.FIELDS.NO_REACH_OUT")}</i>
							) : (
								t(contactPurpose.key, contactPurpose.params)
							)}
						</div>
					</div>
				</div>

				<div className="col-12 col-sm-4 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
					<div className="row">
						{/* Email */}
						<div className="col-12">
							{email ?? <i>{t("PARTICIPANT.PROFILE.FIELDS.NO_EMAIL")}</i>}
						</div>

						{/* Coaching method */}
						<div className="col-6 col-sm-12">
							{t("PARTICIPANT.PROFILE.FIELDS.COACHING_METHOD", {
								value: t(
									participantProfile.preferredCoachingMethod
										? Formatter.preferredCoachingMethod(
												participantProfile.preferredCoachingMethod
											)
										: Formatter.NO_DATA
								),
							})}
						</div>

						{/* Whole health */}
						<div className="col-6 col-sm-12">
							{participantProfile.shippingAddressState ?? (
								<i>{t("PARTICIPANT.PROFILE.FIELDS.NO_STATE")}</i>
							)}
						</div>

						{/* Hedis goals */}
						<div className="col-6 col-sm-12">
							<ParticipantHedisGoals participantContext={participantContext} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ParticipantProfileInfo;
