import { Physician, PhysicianSpecialty } from "../../entities/physician.model";

export class PtPhysiciansInfoForm {
	data: PtPhysicianInfo[];

	constructor(params: Physician[]) {
		this.data = params.map((x) => new PtPhysicianInfo(x));
	}
}

export class PtPhysicianInfo {
	id: number | null;
	participantId: number;
	name: string | null;
	email: string | null;
	phone: string | null;
	fax: string | null;
	address: string | null;
	specialty: PhysicianSpecialty | null;
	deleted: boolean;
	sendHealthSummaryReport: boolean;
	verified: boolean;
	createdOn: string | null;
	updatedOn: string | null;
	deletedOn: string | null;

	constructor(params: Partial<Physician>) {
		this.id = params.id ?? null;
		this.participantId = params.participantId!;
		this.name = params.name ?? null;
		this.email = params.email ?? null;
		this.phone = params.phone ?? null;
		this.fax = params.fax ?? null;
		this.address = params.address ?? null;
		this.specialty = params.specialty ?? null;
		this.deleted = params.deleted ?? false;
		this.sendHealthSummaryReport = params.sendHealthSummaryReport ?? false;
		this.verified = params.verified ?? false;
		this.createdOn = params.createdOn ?? null;
		this.updatedOn = params.updatedOn ?? null;
		this.deletedOn = params.deletedOn ?? null;
	}
}
