import { Tooltip } from "primereact/tooltip";
import UiButton from "../../../components/button/Button";
import { useTranslation } from "react-i18next";
import { useMetriport } from "../../../hooks/useMetriport";
import { useState } from "react";
import { Formatter } from "../../../services/formatter/formatter.service";

const ParticipantMetriport = ({ participantId }: { participantId: number }) => {
	const [enabled, setEnabled] = useState(false);

	return (
		<div className="participant_overview__actions__metriport">
			{!enabled ? (
				<UiButton
					className="p-button-rounded p-button-sm"
					label={"PARTICIPANT.DASHBOARD.OVERVIEW.METRIPORT.STATE_LOAD"}
					onClick={() => setEnabled(true)}
				/>
			) : (
				<MetriportResult participantId={participantId} />
			)}
		</div>
	);
};

const MetriportResult = ({ participantId }: { participantId: number }) => {
	const { t } = useTranslation("common");
	const metriportQuery = useMetriport({ participantId });

	return (
		<>
			{/* Metriport: tooltip (only when there's an error) */}
			<Tooltip
				target={`#metriportTooltip`}
				position="bottom"
				content={t((metriportQuery.error as Error)?.message)}
				disabled={!metriportQuery.isError}
				event="hover"
			/>
			<span id="metriportTooltip">
				{/* Metriport: generate report */}
				<UiButton
					className="p-button-rounded p-button-sm"
					loading={metriportQuery.isInitialLoading}
					label={
						metriportQuery.isInitialLoading
							? "PARTICIPANT.DASHBOARD.OVERVIEW.METRIPORT.STATE_LOADING"
							: metriportQuery.isError
								? "PARTICIPANT.DASHBOARD.OVERVIEW.METRIPORT.TITLE"
								: metriportQuery.isSuccess
									? "PARTICIPANT.DASHBOARD.OVERVIEW.METRIPORT.STATE_SUCCESS"
									: "PARTICIPANT.DASHBOARD.OVERVIEW.METRIPORT.STATE_LOAD"
					}
					disabled={metriportQuery.isInitialLoading || metriportQuery.isError}
					onClick={() => {
						if (metriportQuery.data) {
							console.log("metriportQuery.data: ", metriportQuery.data);
							// Already fetched data --> open pdf
							const blob = Formatter.base64ToBlob(
								metriportQuery.data.metadata,
								"application/pdf"
							);
							Formatter.openFile(blob, "application/pdf");
						}
					}}
				/>
			</span>
		</>
	);
};
export default ParticipantMetriport;
