import { HelperService } from "../../../services/helpers/helper.service";
import {
	ParticipantStatus,
	UnenrollmentReason,
	TestingFrequency,
	ParticipantProfile,
} from "../../entities/participant.model";

export class PtKannactInfoForm {
	isWholeHealth: boolean;
	institutionId: number;
	institution: string;
	coachId: number | null;
	billingProviderId: number | null;
	status: ParticipantStatus;
	unenrollmentReason: UnenrollmentReason | string | null;
	unenrollmentOtherReason: string | null;
	unenrollmentAdditionalComments: string | null;
	glucometerFrequencyReading: number | null;
	glucometerFrequencyPeriod: TestingFrequency | null;
	bpFrequencyReading: number | null;
	bpFrequencyPeriod: TestingFrequency | null;
	weightFrequencyReading: number | null;
	weightFrequencyPeriod: TestingFrequency | null;
	emailPrincipal: string | null;
	phonePrincipal: string | null;
	referrerEmail: string | null;
	referrerName: string | null;
	isTest: boolean;
	isCovered: boolean;
	claimsElegible: boolean;

	constructor(params: ParticipantProfile) {
		this.emailPrincipal = HelperService.getPrincipalEmail(params);
		this.phonePrincipal = HelperService.getPrincipalPhone(params, false);
		this.isWholeHealth = params.isWholeHealth;
		this.institutionId = params.institutionId;
		this.institution = params.institution;
		this.coachId = params.coachId;
		this.status = params.status;
		this.unenrollmentReason = params.unenrollmentReason;
		this.unenrollmentOtherReason = params.unenrollmentOtherReason;
		this.unenrollmentAdditionalComments = params.unenrollmentAdditionalComments;
		this.glucometerFrequencyReading = params.glucometerFrequencyReading;
		this.glucometerFrequencyPeriod = params.glucometerFrequencyPeriod;
		this.bpFrequencyReading = params.bpFrequencyReading;
		this.bpFrequencyPeriod = params.bpFrequencyPeriod;
		this.weightFrequencyReading = params.weightFrequencyReading;
		this.weightFrequencyPeriod = params.weightFrequencyPeriod;
		this.isTest = params.isTest;
		this.isCovered = params.isCovered;
		this.claimsElegible = params.claimsElegible;
		this.referrerEmail = params.referrerEmail;
		this.referrerName = params.referrerName;
		this.billingProviderId = params.billingProviderId;
	}
}
