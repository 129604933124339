import { useQuery, useQueryClient } from "@tanstack/react-query";
import { EndpointsService } from "../../../services/endpoints/endpoints.service";

export const useInstitutionDetails = ({ institutionId }: { institutionId: number }) => {
	const queryClient = useQueryClient();
	const queryKey = ["institution-details", { institutionId }];

	const query = useQuery(queryKey, async () => {
		return await EndpointsService.dataRetriever.getInstitutionDetails({
			institutionId: institutionId,
		});
	});

	return {
		...query,
		invalidate: () => queryClient.invalidateQueries(queryKey),
	};
};
