import axios, { AxiosError, AxiosResponse } from "axios";
import {
	FieldMessageSeverity,
	UiFieldMessageProps,
} from "../../models/components/field-message.model";
import { EndpointRequest } from "../../models/endpoints/endpoints.model";
import { getCookieToken } from "../session/auth.service";
import { getAuth0 } from "../auth0/auth0.service";

export const mappedAxiosError = (
	error?: AxiosError,
	customMessage?: string
): UiFieldMessageProps => {
	const httpStatus: number[] = [400, 401, 403, 404, 405, 409, 500, 502, 503];
	const axiosCodes: string[] = [
		"ERR_FR_TOO_MANY_REDIRECTS",
		"ERR_BAD_OPTION_VALUE",
		"ERR_BAD_OPTION",
		"ERR_NETWORK",
		"ERR_DEPRECATED",
		"ERR_CONFLICTS",
		"ERR_BAD_RESPONSE",
		"ERR_BAD_REQUEST",
		"ERR_CANCELED",
		"ECONNABORTED",
		"ETIMEDOUT",
	];

	const errorMsg: UiFieldMessageProps = {
		severity: FieldMessageSeverity.DANGER,
		label: "UI_COMPONENTS.FIELD_MESSAGE.HTTP.ERROR",
	};

	if (!error && customMessage) errorMsg.label = customMessage;
	else if (
		customMessage &&
		error?.response?.status &&
		(!httpStatus.includes(error.response.status) || error.response.status === 500)
	)
		errorMsg.label = customMessage;
	else if (error?.response?.status && httpStatus.includes(error.response.status))
		errorMsg.label = `UI_COMPONENTS.FIELD_MESSAGE.HTTP.${error.response.status}`;
	else if (error?.code && axiosCodes.includes(error.code))
		errorMsg.label = `UI_COMPONENTS.FIELD_MESSAGE.HTTP.${error.code}`;

	return errorMsg;
};

export default class HttpService {
	// Endpoints URL
	private domain: string | null = null;

	// Axios client
	private axiosClient = axios.create({
		timeout: 900000000,
	});

	// Public HTTP methods
	get = (url: string, request?: EndpointRequest): Promise<never> =>
		this.setRequest("get", this.setUrl(this.domain!, url), request);
	post = (url: string, request: EndpointRequest): Promise<never> =>
		this.setRequest("post", this.setUrl(this.domain!, url), request);
	put = (url: string, request: EndpointRequest): Promise<never> =>
		this.setRequest("put", this.setUrl(this.domain!, url), request);
	delete = (url: string, request?: EndpointRequest): Promise<never> =>
		this.setRequest("delete", this.setUrl(this.domain!, url), request);
	patch = (url: string, request?: EndpointRequest): Promise<never> =>
		this.setRequest("patch", this.setUrl(this.domain!, url), request);

	constructor(domain: string) {
		this.domain = domain;
	}

	private setUrl = (domain: string, endpointUrl: string) => `${domain}${endpointUrl}`;

	private setRequest = async (
		method: string,
		url: string,
		request?: EndpointRequest
	): Promise<never> => {
		// Add token: Authorization
		const token: string | undefined = getCookieToken();
		if (token) {
			this.axiosClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
		}

		let result: Promise<never>;

		switch (method) {
			case "get":
				result = this.axiosClient.get(url, request?.config);
				break;
			case "post":
				result = this.axiosClient.post(url, request?.body, request?.config);
				break;
			case "put":
				result = this.axiosClient.put(url, request?.body, request?.config);
				break;
			case "delete":
				result = this.axiosClient.delete(url, request?.config);
				break;
			case "patch":
				result = this.axiosClient.patch(url, request?.body, request?.config);
				break;
			default:
				console.error("HttpService: No valid method found");
				result = this.axiosClient.get(url, request?.config);
				break;
		}

		// Handle success/error
		result = result
			.then((response: AxiosResponse<never>) => {
				return response.data;
			})
			.catch((error: AxiosError) => {
				// if (error.response?.status === 401) {
				// 	console.log(
				// 		"Unauthenticated error: " +
				// 			error.response?.status +
				// 			" - " +
				// 			error.response?.statusText
				// 	);
				// 	const auth0 = getAuth0();

				// 	if (auth0) {
				// 		console.log("Redirecting to login page due that the session has expired");
				// 		auth0.logout({
				// 			logoutParams: {
				// 				returnTo:
				// 					window.location.origin + "/login?errorType=session_expired",
				// 			},
				// 		});
				// 	}

				// 	// by returning a promise that neither resolves nor rejects, the request will be in a pending state until the user is redirected
				// 	// to the login page
				// 	return new Promise(() => {});
				// }

				if (error.response?.status === 401) {
					const rawUnauthorizedErrors =
						window.localStorage.getItem("unauthorized-errors");

					let unauthorizedErrors = rawUnauthorizedErrors
						? JSON.parse(rawUnauthorizedErrors)
						: [];

					const newError: { [key: string]: any } = {
						datetime: new Date().toISOString(),
						error,
						// request and response need to be explicitly included otherwise they're not serialized upon calling JSON.stringify
						request: error.request,
						response: error.response,
					};

					unauthorizedErrors = [newError, ...unauthorizedErrors.slice(0, 10)];

					window.localStorage.setItem(
						"unauthorized-errors",
						JSON.stringify(unauthorizedErrors)
					);
				}

				return Promise.reject(error);
			});

		return result;
	};
}
