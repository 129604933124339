import { useContext, useState } from "react";
import { Form } from "react-final-form";
import UiButton from "../../../components/button/Button";
import UiInputTextarea from "../../../components/input-textarea/InputTextarea.component";
import { ParticipantContext } from "../../../contexts/Participant.context";
import { ParticipantContextProps } from "../../../models/contexts/participant-context.model";
import UiFieldMessage from "../../../components/field-message/FieldMessage.component";
import { FieldMessageSeverity } from "../../../models/components/field-message.model";
import { EndpointsService } from "../../../services/endpoints/endpoints.service";
import UiCard from "../../../components/card/Card";
import FormSection from "../../../components/form-section/FormSection.component";
import ParticipantMetriport from "./ParticipantMetriport";

const ParticipantOverview = () => {
	const participantContext = useContext<ParticipantContextProps>(ParticipantContext);
	const participantProfile = participantContext.participant!;

	/**
	 * OVERVIEW
	 */

	const [editing, setEditing] = useState(false);
	const [errorOnSubmitForm, setErrorOnSubmitForm] = useState(false);
	const [overviewData, setOverviewData] = useState(participantProfile.overview);
	const onSubmit = async (form: { overview: string }) => {
		try {
			setErrorOnSubmitForm(false);
			await EndpointsService.dataRetriever.updateOverview({
				participantId: participantContext.participant?.id as number,
				body: {
					overview: form.overview,
				},
			});
			setOverviewData(form.overview);
			participantProfile.overview = form.overview;
			setEditing(false);
		} catch (err) {
			setErrorOnSubmitForm(true);
		}
	};

	return (
		<UiCard
			title="PARTICIPANT.DASHBOARD.OVERVIEW.OVERVIEW_TITLE"
			titleUnderline={false}
			className="participant_overview"
			customHeader={
				<div className="participant_overview__actions">
					{/* Edit overview */}
					<UiButton
						className="p-button-text neutral"
						icon="pi pi-pencil"
						onClick={() => setEditing(true)}
						disabled={editing}
					/>
					<ParticipantMetriport participantId={participantProfile.id} />
				</div>
			}
		>
			{!editing ? (
				overviewData
			) : (
				<Form
					onSubmit={onSubmit}
					initialValues={{ overview: overviewData }}
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<FormSection className="mt-2">
								<UiInputTextarea
									id="overview"
									name="overview"
									removeBottomSpacer={true}
									disabled={submitting || !editing}
								/>
							</FormSection>

							{errorOnSubmitForm && (
								<div className="form-message">
									<UiFieldMessage
										severity={FieldMessageSeverity.DANGER}
										label="UI_COMPONENTS.FIELD_MESSAGE.HTTP.ERROR"
									/>
								</div>
							)}

							<div className="action-buttons">
								<UiButton
									label="UI_COMPONENTS.BUTTONS.CANCEL"
									type="button"
									className="p-button-outlined p-button-rounded p-button-sm"
									onClick={() => setEditing(false)}
									disabled={submitting}
								/>
								<UiButton
									label={
										submitting
											? "UI_COMPONENTS.BUTTONS.SAVING"
											: "UI_COMPONENTS.BUTTONS.SAVE"
									}
									type="submit"
									className="p-button-rounded p-button-sm"
									loading={submitting}
									disabled={submitting}
								/>
							</div>
						</form>
					)}
				/>
			)}
		</UiCard>
	);
};
export default ParticipantOverview;
